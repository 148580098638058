@media only screen and (min-width: 768){
  .col-md-0{
    display: none;
  }
}

@media only screen and (max-width: 768px){
  .col-sm-0{
    display: none;
  }
}
