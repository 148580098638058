* {
    font-family: 'Dancing Script';
}

.carousel-inner {
    max-height: 500px;
}

.carousel-caption {
    bottom: 75px;
}

.carousel-img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 9;
}

.carousel-back {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: .5;
    filter: blur(30px);
    width: 100%;
    height: 500px;
    position: relative;
    z-index: 1;
}